<template>
  <a-modal
    :title="isEdit ? '编辑关系' : '添加关系'"
    :visible="open"
    :maskClosable="false"
    @ok="save"
    @cancel="cancel"
    width="600px"
    destroyOnClose
  >
    <div @mouseover="topCenterMouseover" slot="closeIcon">
      <a-icon type="close" />
    </div>
    <a-form-model
      @mouseover="topCenterMouseover"
      style="padding-bottom: 70px;"
      ref="dataForm"
      layout="horizontal"
      :model="formData"
      v-bind="layout"
    >
      <a-form-model-item label="人物" prop="originName">
        <a-select
          show-search
          placeholder="请选择人物"
          option-filter-prop="label"
          v-model="formData.anotherTermId"
          style="width: 100%"
          :filter-option="filterOption"
        >
          <a-select-option v-for="item in roleList" :disabled="gxList.some(gx=>gx.anotherTermId==item.value)" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="关系" prop="processedName">
        <a-input v-model="formData.name" placeholder="请输入关系说明"></a-input>
      </a-form-model-item>
      <a-form-model-item label="敬语/平语" prop="debut">
        <a-row>
          <a-col :span="14">
            <a-radio-group
              v-model="formData.honorific"
              @change="value => radioChange(value, formData)"
              style="width:100%"
            >
              <a-radio value="敬语">敬语</a-radio>
              <a-radio value="平语">平语</a-radio>
              <a-radio value="其他">其他</a-radio>
            </a-radio-group>
          </a-col>
          <a-col :span="10">
            <a-input placeholder="请输入" v-if="formData.honorific == '其他'" v-model="formData.elseInfo" />
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item label="称呼" prop="description">
        <a-textarea
          v-model="formData.call"
          :auto-size="{ minRows: 1, maxRows: 10 }"
          placeholder="请输入称呼"
        ></a-textarea>
      </a-form-model-item>
    </a-form-model>
    <template slot="footer">
      <div @mouseover="topCenterMouseover">
        <a-button key="back" @click="cancel">
          取消
        </a-button>
        <a-button key="submit" type="primary" @click="save">
          确定
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { getAction, postAction, putAction } from '@/api/manage'
export default {
  props: {
    roleList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      open: false,
      formData: {
        honorific: '敬语',
        call: '',
        name: '',
        anotherTermId: undefined
      },
      /*       rules: {
        termTypeName: [{ required: true, message: '请选择分类', trigger: 'change' }],
        originName: [{ required: true, message: '请输入原文名', trigger: 'blur' }],
        debut: [{ required: true, message: '请输入初次登场章节', trigger: 'blur' }],
        gender: [{ required: true, message: '请选择性别', trigger: 'change' }],
        identity: [{ required: true, message: '请输入身份（职业）', trigger: 'blur' }],
        description: [{ required: true, message: '请输入说明', trigger: 'blur' }]
      }, */
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
      },
      addIndex: -1,
      isEdit: false,
      gxList:[]
    }
  },
  methods: {
    topCenterMouseover() {
      this.$emit('topCenterMouseover')
    },
    cancel() {
      this.open = false
      this.formData = {
        honorific: '敬语',
        call: '',
        name: '',
        anotherTermId: undefined
      }
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    openModal(gxList, index) {
      this.isEdit = false
      this.gxList = gxList
      this.addIndex = index
      this.open = true
    },
    editModal(gxList, index) {
      this.isEdit = true
      this.formData = {
        honorific: gxList[index].honorific,
        call: gxList[index].call,
        elseInfo: gxList[index].elseInfo,
        name: gxList[index].name,
        anotherTermId: gxList[index].anotherTermId+''
      }
      this.addIndex = index
      this.open = true
    },
    radioChange(e, item) {
      item = Object.assign(item, { honorific: e.target.value })
      this.$forceUpdate()
    },
    save() {
      if (!this.formData.anotherTermId) return this.$message.error('请选择人物')
      if (this.isEdit) {
        let params = { ...this.formData, index: this.addIndex }
        this.$emit('editGx', params)
      } else {
        let params = { ...this.formData, index: this.addIndex }
        this.$emit('saveGx', params)
      }

      this.open = false
      this.formData = {
        honorific: '敬语',
        call: '',
        name: '',
        anotherTermId: undefined
      }
    }
  }
}
</script>

<style lang="less" scoped>
/deep/ .ant-modal-wrap {
  z-index: 1050 !important;
}
.ant-form {
  padding-bottom: unset !important;
}
</style>
